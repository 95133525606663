
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  computed: mapGetters(['currentUser']),
})
export default class AdminTabs extends Vue {
  private currentUser!: any;

  get tabs() {
    return [
      { name: 'administrationUsers', text: this.$i18n.t('strings.users') },
      { name: 'administrationAPIUsers', text: 'API' },
      { name: 'administrationNotifications', text: this.$i18n.t('strings.notifications') },
      { name: 'administrationVerifications', text: this.$i18n.t('strings.verifications') },
      { name: 'administrationUploadIncidents', text: this.$i18n.t('strings.incidentsUpload') },
      { name: 'administrationReport', text: this.$i18n.t('strings.reports'), always: true },
      { name: 'administrationCommercialPrudence', text: this.$i18n.t('strings.cplSubscription') },
    ].filter((item: any) => this.currentUser.isStaff ||
      (!this.currentUser.isStaff && this.currentUser.canDoReports && item.always));
  }
}
