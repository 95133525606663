
import {Component, Vue, Watch} from 'vue-property-decorator';
import {mapGetters} from 'vuex';

import {
  FETCH_USERS, SEND_NOTIFICATION, FETCH_SEARCH_USER,
} from '@/store/actions.type';

@Component({
  computed: mapGetters(['users', 'usersCount', 'searchUsers', 'errors']),
})

export default class NotificationsDashboard extends Vue {
  public users!: object[];
  public errors!: any;
  public searchUsers!: object[];
  public notifyForm: {
    sendTo: number,
    subject: string,
    body: string,
    recipients: number[],
    file: any,
  } = {
    sendTo: 0,
    subject: '',
    body: '',
    recipients: [],
    file: '',
  };
  public notifyFormError: string = '';
  public notifyFormSubmitted: boolean = false;

  public async mounted() {
    this.notifyForm.sendTo = 1;
    await this.$store.dispatch(FETCH_USERS);
    await this.$store.dispatch(FETCH_SEARCH_USER);
  }

  get formattedSearchUsers() {
    return this.searchUsers.filter((item: any) => item.first_name || item.last_name).map((item: any) => {
      return {key: item.id, label: `${item.last_name} ${item.first_name}`};
    });
  }

  private async submitNotification() {
    if (this.notifyForm.sendTo !== 2 && this.notifyForm.sendTo !== 1) {
      this.notifyFormError = 'emptySendTo';
      return;
    }
    if (this.notifyForm.sendTo === 2 && !this.notifyForm.recipients.length) {
      this.notifyFormError = 'emptyRecipients';
      return;
    }
    if (!this.notifyForm.subject.length) {
      this.notifyFormError = 'emptySubject';
      return;
    }
    if (!this.notifyForm.body.length) {
      this.notifyFormError = 'emptyBody';
      return;
    }
    const preparedData = {
      send_to: this.notifyForm.sendTo,
      recipients: this.notifyForm.recipients.map((item: any) => item.key),
      body: this.notifyForm.body,
      subject: this.notifyForm.subject,
      file: this.notifyForm.file,
    };
    await this.$store.dispatch(SEND_NOTIFICATION, preparedData);
    if (this.errors) {
      this.notifyFormError = 'serverError';
      return;
    }
    this.notifyFormSubmitted = true;
  }

  private clearNotifyForm() {
    this.notifyForm = {
      sendTo: 1,
      subject: '',
      body: '',
      recipients: [],
      file: '',
    };
    this.notifyFormSubmitted = false;
  }

}
