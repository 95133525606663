
import {Component, Vue, Prop} from 'vue-property-decorator';
import ApiService from '@/common/api.service';

@Component({})
export default class APIUserSettingsPage extends Vue {

  public userId: number = 0;
  public userName: string = '';
  public verificationsCount: number = 0;
  public verificationFormSubmitted: boolean = false;
  private dataLoading: boolean = true;

  public async mounted() {
    this.userId = parseInt(this.$route.params.id, 10);
    const resp: any = await ApiService.query('/impact/api_key_client/' + this.userId);
    this.userName = resp.data.name;
    this.verificationsCount = resp.data.verifications_count;
    this.dataLoading = false;
  }

  public async submitVerificationCount() {
    this.verificationFormSubmitted = true;
    await ApiService.put('/impact/api_key_client/' + this.userId,
        {id: this.userId, name: this.userName, verifications_count: this.verificationsCount});
    this.verificationFormSubmitted = false;
  }
}
