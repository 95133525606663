
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Spinner extends Vue {
  @Prop({ default: '#c7c7cc' })
  private color!: string;

  @Prop( { default: 24 })
  private size!: number;

  @Prop( { default: '' })
  private elemClass!: string;

  private center: number = this.size / 2;
}
