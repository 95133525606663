
import {Vue, Component, Watch} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {
  FETCH_USERS, CREATE_USER, FETCH_OFFICE_FUNCTION, DELETE_USER, FETCH_SEARCH_USER,
} from '@/store/actions.type';
import {convertDate} from '@/common/helpers';
import Dropdown from '@/components/Dropdown.vue';
import ApiService from '@/common/api.service';

@Component({
  components: {Dropdown},
  computed: mapGetters(['users', 'usersCount', 'isLoading', 'officeFunctionOptions', 'searchUsers', 'errors']),
})


export default class UsersDashboard extends Vue {

  public users!: object[];
  public errors!: any;
  public usersCount!: number;
  public userAccessLevel: number = 0;
  public showError: boolean = false;
  public showDelete: boolean = false;
  public showCreateForm: boolean = false;
  public userToDelete: number = 0;
  public officeFunctionOptions!: object[];
  public perPage: number = 20;
  public pageParams: {
    page: number,
    sortBy: string,
    sortDesc: boolean,
  } = {
    page: 1,
    sortBy: 'id',
    sortDesc: true,
  };
  public formData: {
    id: string | null,
    username: string,
  } = {
    id: null,
    username: '',
  };
  private allUsers: any = [];
  private searchQuery: any = '';

  @Watch('userAccessLevel')
  public async onUserAccessLevelChange() {
    await this.getUsers();
  }

  public showCreate(id: number) {
    this.showError = false;
    this.formData.id = String(id);
    this.showCreateForm = true;
  }


  public resetCreateModal() {
    this.formData = {
      id: null,
      username: '',
    };
  }

  public async createUser(bvModalEvt: any) {
    if (await this.$store.dispatch(CREATE_USER, this.formData)) {
      this.$root.$emit('bv::hide::modal', String(this.formData.id));
      this.showCreateForm = false;
      await this.getUsers(this.pageParams.page);
    } else {
      this.showError = true;
    }
  }

  public async deleteUser() {
    await this.$store.dispatch(DELETE_USER, this.userToDelete);
    this.showDelete = false;
    await this.getUsers(this.pageParams.page);
  }

  private usernameFormat(value: string, event: any) {
    let newVal = value.replace(/[^a-zA-Z0-9._]/g, '');
    if (newVal.length > 150) {
      newVal = newVal.slice(0, 150);
    }
    return newVal;
  }

  private async mounted() {
    await this.$store.dispatch(FETCH_OFFICE_FUNCTION);
    await this.getUsers();
    await this.getAllUsers();
  }


  get numberOfPages(): number {
    return Math.floor(this.usersCount / this.perPage) + 1;
  }

  get formattedUsers() {
    if (this.searchQuery) {
      const t = this.allUsers.filter((item: any, index: number) => {
        if (!item.last_name) {
          item.last_name = '';
        }
        return item.last_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            item.first_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            item.email.includes(this.searchQuery.toLowerCase()) ||
            item.phone.includes(this.searchQuery.toLowerCase()) ||
            item.company.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
      return t.map((item: any, index: number) => {
        const officeFunction: any = item.office_function ?
            this.officeFunctionOptions.filter((opt: any) => opt.id === item.office_function) : [];
        return {
          index: (this.pageParams.page - 1) * this.perPage + index + 1,
          id: item.id,
          registrationDate: convertDate(item.registration_date),
          ip_address: item.ip_address,
          name: `${item.first_name} ${item.last_name}`,
          email: item.email,
          phone: item.phone,
          company: item.company,
          inn: item.inn,
          officeFunction: officeFunction.length ? officeFunction[0][`name_${this.$i18n.locale}`] : '',
          user: item.user,
          verificationCount: item.verification_count,
        };
      });

    }
    return this.users.map((item: any, index: number) => {
      const officeFunction: any = item.office_function ?
          this.officeFunctionOptions.filter((opt: any) => opt.id === item.office_function) : [];
      return {
        index: (this.pageParams.page - 1) * this.perPage + index + 1,
        id: item.id,
        registrationDate: convertDate(item.registration_date),
        ip_address: item.ip_address,
        name: `${item.first_name} ${item.last_name}`,
        email: item.email,
        phone: item.phone,
        company: item.company,
        inn: item.inn,
        officeFunction: officeFunction.length ? officeFunction[0][`name_${this.$i18n.locale}`] : '',
        user: item.user,
        verificationCount: item.verification_count,
      };
    });
  }

  private async getUsers(page: number = 1) {
    const params: any = {
      page,
      ordering: (this.pageParams.sortDesc ? '-' : '') + this.pageParams.sortBy,
    };
    if (this.userAccessLevel) {
      params.group_id = this.userAccessLevel;
    }
    await this.$store.dispatch(FETCH_USERS, params);
  }

  private async getAllUsers() {
    const resp: any = await ApiService.query('/impact/profile');
    this.allUsers = resp.data;
  }

  @Watch('formData')
  private async findUser(e: any) {
    await this.$store.dispatch(FETCH_SEARCH_USER, this.formData.username);
  }

  private viewUser(userEditId: number) {
    window.open(`/user/${userEditId}`, '_blank');
  }

  get fullBorder() {
    const value: boolean = true;
    return value;
  }


}
