
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {FETCH_OFFICE_FUNCTION, FETCH_VERIFICATION_STATISTICS} from '@/store/actions.type';
import {mapGetters} from 'vuex';

@Component({
  computed: mapGetters(['verificationStatistics', 'atiVerificationStatistics']),
})
export default class VerificationsDashboard extends Vue {

  @Prop({default: false})
  public loadData!: boolean;

  public verificationStatistics!: object[];
  public atiVerificationStatistics!: object[];

  public async fetchStatistics() {
    await this.$store.dispatch(FETCH_VERIFICATION_STATISTICS);
  }

  get fields() {
    return [
      {label: 'Месяц', key: 'date'},
      {label: 'КР', key: '1'},
      {label: 'ФССП', key: '2'},
      {label: 'ОСАГО', key: '3'},
      {label: 'ВУ', key: '4'},
      {label: 'Паспорт', key: '6'},
      {label: 'ФИО-телефон', key: '7'},
      {label: 'Благонадёжность', key: '9'},
      {label: 'Лицо', key: '10'},
      {label: 'ИНН', key: '11'},
      {label: 'ИНН без ДР', key: '12'},
      {label: 'ИНН с ВУ', key: '14'},
      {label: this.$t('strings.total'), key: 'all'},
    ];
  }

  get ati_fields() {
    return [
      {label: 'Месяц', key: 'date'},
      {label: 'Количество', key: '1'},
    ];
  }

  get items() {
    const countVerifications: any = {};
    const items = this.verificationStatistics.map((item: any) => {
      const tmp = {
        date: `${this.$t(`months[${item.m}]`)} ${item.y}`, ...item.s,
        all: Object.keys(item.s).map((verification: any) => item.s[verification])
            .reduce((accumulator: number, currentValue: number) => accumulator + currentValue),
      };
      Object.keys(item.s).map((verification: any) => {
        if (!countVerifications[verification]) {
          countVerifications[verification] = 0;
        }
        countVerifications[verification] += item.s[verification];
      });
      return tmp;
    });
    const verificationCount = Object.keys(countVerifications)
        .map((verification: any) => countVerifications[verification]);
    items.push({
      date: this.$t('strings.total'), ...countVerifications,
      all: verificationCount.length ? verificationCount
          .reduce((accumulator: number, currentValue: number) => accumulator + currentValue) : 0,
    });
    return items;
  }

  get ati_items() {
    const countVerifications: any = {};
    const items = this.atiVerificationStatistics.map((item: any) => {
      const tmp = {
        date: `${this.$t(`months[${item.m}]`)} ${item.y}`, ...item.s,
        all: Object.keys(item.s).map((verification: any) => item.s[verification])
            .reduce((accumulator: number, currentValue: number) => accumulator + currentValue),
      };
      Object.keys(item.s).map((verification: any) => {
        if (!countVerifications[verification]) {
          countVerifications[verification] = 0;
        }
        countVerifications[verification] += item.s[verification];
      });
      return tmp;
    });
    const verificationCount = Object.keys(countVerifications)
        .map((verification: any) => countVerifications[verification]);
    items.push({
      date: this.$t('strings.total'), ...countVerifications,
      all: verificationCount.length ? verificationCount
          .reduce((accumulator: number, currentValue: number) => accumulator + currentValue) : 0,
    });
    return items;
  }

  private async mounted() {
    await this.fetchStatistics();
  }

}
