
import {Component, Vue} from 'vue-property-decorator';
import AdministrationTabs from '@/components/Administration/AdministrationTabs.vue';
import UsersDashboard from '@/components/Administration/Dashboards/UsersDashboard.vue';
import APIUsersDashboard from '@/components/Administration/Dashboards/APIUsersDashboard.vue';
import NotificationsDashboard from '@/components/Administration/Dashboards/NotificationsDashboard.vue';
import VerificationsDashboard from '@/components/Administration/Dashboards/VerificationsDashboard.vue';
import UploadIncidentsDashboard from '@/components/Administration/Dashboards/UploadIncidentsDashboard.vue';
import ReportDashboard from '@/components/Administration/Dashboards/ReportDashboard.vue';
import CommercialPrudenceDashboard from '@/components/Administration/Dashboards/CommercialPrudenceDashboard.vue';
import { mapGetters } from 'vuex';

@Component({
  components: {
    AdministrationTabs, UsersDashboard, APIUsersDashboard, NotificationsDashboard, VerificationsDashboard,
    UploadIncidentsDashboard, ReportDashboard, CommercialPrudenceDashboard,
  },
  computed: mapGetters(['currentUser']),
})
export default class AdminPage extends Vue {
  private currentUser!: any;

  private mounted() {
    if (this.currentUser.canDoReports) {
      this.$router.replace({ name: 'administrationReport' });
    }
  }

  get dashboard() {
    const baseTab = this.currentUser.canDoReports ? 'ReportDashboard' : 'UsersDashboard';
    let componentName = this.$route.name ?
        `${this.$route.name.replace('administration', '')}Dashboard` : '';
    if (componentName === 'Dashboard') {
      componentName = baseTab;
    }
    return componentName;
  }
}
