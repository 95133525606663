export const CHECK_RISK = 1;
export const CHECK_FSSP = 2;
export const CHECK_OSAGO = 3;
export const CHECK_DRIVER = 4;
export const CHECK_PASSPORT_COMPLEX = 6;
export const CHECK_PHONE = 7;
export const CHECK_TRUST_PERSON = 9;
export const CHECK_FACE = 10;
export const CHECK_REPUTATION = 13;
export const CHECK_PARTICIPANT = 15;

export const FIELD_PHOTO = 1; // Документ водителя
export const FIELD_LAST_NAME = 2; // Фамилия
export const FIELD_FIRST_NAME = 3; // Имя
export const FIELD_MID_NAME = 4; // Отчество
export const FIELD_PHONE = 5; // Телефон
export const FIELD_PASSPORT = 6; // Паспорт
export const FIELD_PASSPORT_DATE = 7; // Дата выдачи
export const FIELD_BIRTHDATE = 8; // Дата рождения
export const FIELD_TRACTOR_LICENSE_PLATE = 9; // Номер тягача
export const FIELD_TRAILER_LICENSE_PLATE = 10; // Номер прицепа
export const FIELD_TRACTOR_VIN = 11; // VIN тягача
export const FIELD_TRAILER_VIN = 12; // VIN прицепа
export const FIELD_DRIVING_LICENSE = 13; // Серия и номер ВУ
export const FIELD_DRIVING_LICENSE_DATE = 14; // Дата выдачи ВУ
export const FIELD_OGRN = 15; // ОГРН
export const FIELD_INN = 16; // ИНН
export const FIELD_ATI_SU_ID = 17; // ID ATI.su
