
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {FETCH_UPLOADED_XLSX, UPLOAD_XLSX} from '@/store/actions.type';
import {mapGetters} from 'vuex';
import {convertDate} from '@/common/helpers';

@Component({
  computed: mapGetters(['uploadedXlsx', 'isLoading', 'uploadError']),
})
export default class UploadIncidentsDashboard extends Vue {

  @Prop({default: false})
  public loadData!: boolean;

  public uploadedXlsx!: object[];
  public isLoading!: boolean;
  public uploadError!: boolean;
  public incidentsFile: any = '';


  public async fetchData() {
    await this.$store.dispatch(FETCH_UPLOADED_XLSX);
  }

  public async uploadXlsx() {
    if (!this.incidentsFile) {
      return;
    }
    await this.$store.dispatch(UPLOAD_XLSX, this.incidentsFile);
    if (!this.uploadError) {
      const incidentsFile: any = this.$refs.incidentsFile;
      incidentsFile.reset();
      await this.fetchData();
    }
  }

  get fields() {
    return [
      {label: 'ID', key: 'id'},
      {label: this.$t('strings.dateUpload'), key: 'added'},
      {label: this.$t('strings.dateImport'), key: 'imported'},
    ];
  }

  get items() {
    return this.uploadedXlsx.map((item: any, index: number) => {
      return {
        id: item.id,
        added: convertDate(item.added),
        imported: item.imported ? convertDate(item.imported) : '',
      };
    });
  }

  private async mounted() {
    await this.fetchData();
  }

}
