
import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
import {
  FETCH_USER_SETTINGS, UPDATE_VERIFICATIONS_COUNT, UPDATE_PASSWORD, UPDATE_PASSWORD_SELF,
  UPDATE_SETTINGS, UPDATE_SETTINGS_SELF, UPDATE_DISABLE_VERIFICATIONS, UPDATE_COMMERCIAL_PRUDENCE_LIMIT,
} from '@/store/actions.type';
import {
  FIELD_PHOTO, FIELD_LAST_NAME, FIELD_FIRST_NAME, FIELD_MID_NAME, FIELD_PHONE, FIELD_PASSPORT, FIELD_PASSPORT_DATE,
  FIELD_BIRTHDATE, FIELD_TRACTOR_LICENSE_PLATE, FIELD_TRAILER_LICENSE_PLATE, FIELD_TRACTOR_VIN, FIELD_TRAILER_VIN,
  FIELD_DRIVING_LICENSE, FIELD_DRIVING_LICENSE_DATE, FIELD_OGRN, FIELD_INN, FIELD_ATI_SU_ID,
} from '@/store/verification.type';
import {mapGetters} from 'vuex';
import {convertDate} from '@/common/helpers';
import ApiService from '@/common/api.service';
import PasswordChangeForm from '@/components/Login/PasswordChangeForm.vue';
import saveAs from 'file-saver';

@Component({
  components: {PasswordChangeForm},
  computed: mapGetters(['userSettings', 'currentUser']),
})

export default class UserSettingsPage extends Vue {

  @Prop()
  public selfSettings!: boolean;
  public userSettings!: any;
  public currentUser!: any;
  public userId: number = 0;
  public verificationCount: number = 0;
  public verificationFormSubmitted: boolean = false;
  public disableVerificationFormSubmitted: boolean = false;
  public notificationLanguage: string = '';
  public accessLevel: number = 0;
  public subscribed: boolean = true;
  public initialLoad: boolean = true;
  private registrationDate: string = '';
  private dataLoading: boolean = true;
  private disableCheckRisk: boolean = false;
  private disableCheckFSSP: boolean = false;
  private disableCheckOSAGO: boolean = false;
  private disableCheckDriver: boolean = false;
  private disableCheckPassportComplex: boolean = false;
  private disableCheckPhone: boolean = false;
  private disableCheckFace: boolean = false;
  private disableCheckTrustPerson: boolean = false;
  private disableCheckReputation: boolean = false;
  private disableCheckParticipant: boolean = false;
  private requiredFieldPhoto: boolean = false;
  private requiredFieldLastName: boolean = false;
  private requiredFieldFirstName: boolean = false;
  private requiredFieldMidName: boolean = false;
  private requiredFieldPhone: boolean = false;
  private requiredFieldPassport: boolean = false;
  private requiredFieldPassportDate: boolean = false;
  private requiredFieldBirthdate: boolean = false;
  private requiredFieldTractorLicensePlate: boolean = false;
  private requiredFieldTrailerLicensePlate: boolean = false;
  private requiredFieldTractorVIN: boolean = false;
  private requiredFieldTrailerVIN: boolean = false;
  private requiredFieldDrivingLicense: boolean = false;
  private requiredFieldDrivingLicenseDate: boolean = false;
  private requiredFieldOGRN: boolean = false;
  private requiredFieldINN: boolean = false;
  private requiredFieldAtiSuID: boolean = false;
  private foreignDocuments: boolean = false;
  private enableCommercialPrudence: boolean = false;
  private commercialPrudenceLimitEnabled: boolean = false;
  private commercialPrudenceLimit: number = 0;

  public async mounted() {
    if (!this.selfSettings) {
      await this.fetchData();
    }
    this.dataLoading = false;
    this.fillNotificationLanguage();
  }

  public async fetchData() {
    this.userId = parseInt(this.$route.params.id, 10);
    if (!this.currentUser.isStaff && this.userId !== this.currentUser.id || this.selfSettings) {
      return;
    }
    await this.$store.dispatch(FETCH_USER_SETTINGS, this.userId);
    this.registrationDate = convertDate(this.userSettings.registrationDate);
    this.disableCheckRisk = this.userSettings.disableCheckRisk;
    this.disableCheckFSSP = this.userSettings.disableCheckFSSP;
    this.disableCheckOSAGO = this.userSettings.disableCheckOSAGO;
    this.disableCheckDriver = this.userSettings.disableCheckDriver;
    this.disableCheckPassportComplex = this.userSettings.disableCheckPassportComplex;
    this.disableCheckPhone = this.userSettings.disableCheckPhone;
    this.disableCheckFace = this.userSettings.disableCheckFace;
    this.disableCheckTrustPerson = this.userSettings.disableCheckTrustPerson;
    this.disableCheckReputation = this.userSettings.disableCheckReputation;
    this.disableCheckParticipant = this.userSettings.disableCheckParticipant;

    this.requiredFieldPhoto = (this.userSettings.requiredVettingFields & 2 ** FIELD_PHOTO) > 0;
    this.requiredFieldLastName = (this.userSettings.requiredVettingFields & 2 ** FIELD_LAST_NAME) > 0;
    this.requiredFieldFirstName = (this.userSettings.requiredVettingFields & 2 ** FIELD_FIRST_NAME) > 0;
    this.requiredFieldMidName = (this.userSettings.requiredVettingFields & 2 ** FIELD_MID_NAME) > 0;
    this.requiredFieldPhone = (this.userSettings.requiredVettingFields & 2 ** FIELD_PHONE) > 0;
    this.requiredFieldPassport = (this.userSettings.requiredVettingFields & 2 ** FIELD_PASSPORT) > 0;
    this.requiredFieldPassportDate = (this.userSettings.requiredVettingFields & 2 ** FIELD_PASSPORT_DATE) > 0;
    this.requiredFieldBirthdate = (this.userSettings.requiredVettingFields & 2 ** FIELD_BIRTHDATE) > 0;
    this.requiredFieldTractorLicensePlate = (this.userSettings.requiredVettingFields & 2 ** FIELD_TRACTOR_LICENSE_PLATE)
      > 0;
    this.requiredFieldTrailerLicensePlate = (this.userSettings.requiredVettingFields & 2 ** FIELD_TRAILER_LICENSE_PLATE)
      > 0;
    this.requiredFieldTractorVIN = (this.userSettings.requiredVettingFields & 2 ** FIELD_TRACTOR_VIN) > 0;
    this.requiredFieldTrailerVIN = (this.userSettings.requiredVettingFields & 2 ** FIELD_TRAILER_VIN) > 0;
    this.requiredFieldDrivingLicense = (this.userSettings.requiredVettingFields & 2 ** FIELD_DRIVING_LICENSE) > 0;
    this.requiredFieldDrivingLicenseDate = (this.userSettings.requiredVettingFields & 2 ** FIELD_DRIVING_LICENSE_DATE)
      > 0;
    this.requiredFieldOGRN = (this.userSettings.requiredVettingFields & 2 ** FIELD_OGRN) > 0;
    this.requiredFieldINN = (this.userSettings.requiredVettingFields & 2 ** FIELD_INN) > 0;
    this.requiredFieldAtiSuID = (this.userSettings.requiredVettingFields & 2 ** FIELD_ATI_SU_ID) > 0;
    this.foreignDocuments = this.userSettings.foreignDocuments;

    this.enableCommercialPrudence = this.userSettings.enableCommercialPrudence;
    this.commercialPrudenceLimitEnabled = this.userSettings.commercialPrudenceLimitEnabled;
    this.commercialPrudenceLimit = this.userSettings.commercialPrudenceLimit;
  }

  get userData() {
    if (this.selfSettings) {
      return {user: true, ...this.currentUser};
    } else {
      return this.userSettings;
    }
  }

  public fillNotificationLanguage() {
    this.initialLoad = true;
    this.notificationLanguage = this.userData.notificationLanguage ? this.userData.notificationLanguage : 'ru';
    this.subscribed = this.userData.subscribed;
    this.accessLevel = this.userData.group_id;
    this.$nextTick(async () => {
      this.initialLoad = false;
    });
  }

  @Watch('userData.verification_count')
  public updateVerificationCount() {
    this.verificationCount = this.userData.verificationCount;
  }

  @Watch('$route')
  public async clearData() {
    // this.clearFormMessage();
    // this.password1 = '';
    // this.password2 = '';
    await this.fetchData();
    this.fillNotificationLanguage();
  }

  public async submitVerificationCount() {
    this.verificationFormSubmitted = true;
    await this.$store.dispatch(UPDATE_VERIFICATIONS_COUNT,
        {id: this.userId, verification_count: this.verificationCount});
    this.verificationFormSubmitted = false;
  }

  public async submitDisableVerification() {
    this.disableVerificationFormSubmitted = true;
    await this.$store.dispatch(UPDATE_DISABLE_VERIFICATIONS,
        {id: this.userId, disable_check_risk: this.disableCheckRisk, disable_check_fssp: this.disableCheckFSSP,
          disable_check_osago: this.disableCheckOSAGO, disable_check_passport_complex: this.disableCheckPassportComplex,
          disable_check_phone: this.disableCheckPhone, disable_check_reputation: this.disableCheckReputation,
          disable_check_trust_person: this.disableCheckTrustPerson, disable_check_driver: this.disableCheckDriver,
          disable_check_participant: this.disableCheckParticipant, disable_check_face: this.disableCheckFace,
          required_field_photo: this.requiredFieldPhoto, required_field_last_name: this.requiredFieldLastName,
          required_field_first_name: this.requiredFieldFirstName, required_field_mid_name: this.requiredFieldMidName,
          required_field_phone: this.requiredFieldPhone, required_field_passport: this.requiredFieldPassport,
          required_field_passport_date: this.requiredFieldPassportDate,
          required_field_birthdate: this.requiredFieldBirthdate,
          required_field_tractor_license_plate: this.requiredFieldTractorLicensePlate,
          required_field_trailer_license_plate: this.requiredFieldTrailerLicensePlate,
          required_field_tractor_vin: this.requiredFieldTractorVIN,
          required_field_trailer_vin: this.requiredFieldTrailerVIN,
          required_field_driving_license: this.requiredFieldDrivingLicense,
          required_field_driving_license_date: this.requiredFieldDrivingLicenseDate,
          required_field_ogrn: this.requiredFieldOGRN, required_field_inn: this.requiredFieldINN,
          required_field_ati_su_id: this.requiredFieldAtiSuID,
          foreign_documents: this.foreignDocuments,
          enable_commercial_prudence: this.enableCommercialPrudence,
          commercial_prudence_limit_enabled: this.commercialPrudenceLimitEnabled,
        });
    if (this.commercialPrudenceLimitEnabled) {
      await this.$store.dispatch(UPDATE_COMMERCIAL_PRUDENCE_LIMIT,
        {id: this.userId, commercial_prudence_limit: this.commercialPrudenceLimit});
    }
    this.disableVerificationFormSubmitted = false;
  }

  @Watch('subscribed')
  public async onSubscribedChange() {
    await this.submitSettings();
  }

  @Watch('accessLevel')
  public async onAccessLevelChange() {
    await this.submitSettings();
  }

  @Watch('notificationLanguage')
  public async onLanguageChange() {
    await this.submitSettings();
  }

  public async submitSettings() {
    if (this.initialLoad) {
      return;
    }
    if (this.selfSettings) {
      await this.$store.dispatch(UPDATE_SETTINGS_SELF,
          {notification_language: this.notificationLanguage, subscribed: this.subscribed});
    } else {
      await this.$store.dispatch(UPDATE_SETTINGS,
          {
            id: this.userId, notification_language: this.notificationLanguage, subscribed: this.subscribed,
            group_id: this.accessLevel,
          });
    }
  }

  public async submitPassword(password: string) {
    if (this.selfSettings) {
      await this.$store.dispatch(UPDATE_PASSWORD_SELF, {password});
    } else {
      await this.$store.dispatch(UPDATE_PASSWORD, {id: this.userId, password});
    }
  }

  private async openVettingReport(userId: number) {
    const resp: any = await ApiService.getFile('/impact/user_admin/' + userId + '/vetting_report');
    const contentDisposition = resp.headers['content-disposition'];
    let fileName = 'vetting.xlsx';
    if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) {
            fileName = fileNameMatch[1];
        }
    }
    saveAs(resp.data, fileName);
  }

  private toggleDisableCheckRisk() {
    this.disableCheckRisk = !this.disableCheckRisk;
  }

  private toggleDisableCheckFSSP() {
    this.disableCheckFSSP = !this.disableCheckFSSP;
  }

  private toggleDisableCheckOSAGO() {
    this.disableCheckOSAGO = !this.disableCheckOSAGO;
  }

  private toggleDisableCheckDriver() {
    this.disableCheckDriver = !this.disableCheckDriver;
  }

  private toggleDisableCheckPassportComplex() {
    this.disableCheckPassportComplex = !this.disableCheckPassportComplex;
  }

  private toggleDisableCheckPhone() {
    this.disableCheckPhone = !this.disableCheckPhone;
  }

  private toggleDisableCheckFace() {
    this.disableCheckFace = !this.disableCheckFace;
  }

  private toggleDisableCheckTrustPerson() {
    this.disableCheckTrustPerson = !this.disableCheckTrustPerson;
  }

  private toggleDisableCheckReputation() {
    this.disableCheckReputation = !this.disableCheckReputation;
  }

  private toggleDisableCheckParticipant() {
    this.disableCheckParticipant = !this.disableCheckParticipant;
  }

  private toggleRequiredFieldPhoto() {
    this.requiredFieldPhoto = !this.requiredFieldPhoto;
  }

  private toggleRequiredFieldLastName() {
    this.requiredFieldLastName = !this.requiredFieldLastName;
  }

  private toggleRequiredFieldFirstName() {
    this.requiredFieldFirstName = !this.requiredFieldFirstName;
  }

  private toggleRequiredFieldMidName() {
    this.requiredFieldMidName = !this.requiredFieldMidName;
  }

  private toggleRequiredFieldPhone() {
    this.requiredFieldPhone = !this.requiredFieldPhone;
  }

  private toggleRequiredFieldPassport() {
    this.requiredFieldPassport = !this.requiredFieldPassport;
  }

  private toggleRequiredFieldPassportDate() {
    this.requiredFieldPassportDate = !this.requiredFieldPassportDate;
  }

  private toggleRequiredFieldBirthdate() {
    this.requiredFieldBirthdate = !this.requiredFieldBirthdate;
  }

  private toggleRequiredFieldTractorLicensePlate() {
    this.requiredFieldTractorLicensePlate = !this.requiredFieldTractorLicensePlate;
  }

  private toggleRequiredFieldTrailerLicensePlate() {
    this.requiredFieldTrailerLicensePlate = !this.requiredFieldTrailerLicensePlate;
  }

  private toggleRequiredFieldTractorVIN() {
    this.requiredFieldTractorVIN = !this.requiredFieldTractorVIN;
  }

  private toggleRequiredFieldTrailerVIN() {
    this.requiredFieldTrailerVIN = !this.requiredFieldTrailerVIN;
  }

  private toggleRequiredFieldDrivingLicense() {
    this.requiredFieldDrivingLicense = !this.requiredFieldDrivingLicense;
  }

  private toggleRequiredFieldDrivingLicenseDate() {
    this.requiredFieldDrivingLicenseDate = !this.requiredFieldDrivingLicenseDate;
  }

  private toggleRequiredFieldOGRN() {
    this.requiredFieldOGRN = !this.requiredFieldOGRN;
  }

  private toggleRequiredFieldINN() {
    this.requiredFieldINN = !this.requiredFieldINN;
  }

  private toggleRequiredFieldAtiSuID() {
    this.requiredFieldAtiSuID = !this.requiredFieldAtiSuID;
  }

  private toggleFieldForeignDocuments() {
    this.foreignDocuments = !this.foreignDocuments;
  }

  private toggleEnableCommercialPrudence() {
    this.enableCommercialPrudence = !this.enableCommercialPrudence;
  }

  private toggleCommercialPrudenceLimitEnabled() {
    this.commercialPrudenceLimitEnabled = !this.commercialPrudenceLimitEnabled;
    if (!this.commercialPrudenceLimitEnabled) {
      this.commercialPrudenceLimit = 0;
    }
  }
}
