
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {FETCH_OFFICE_FUNCTION, FETCH_VERIFICATION_STATISTICS} from '@/store/actions.type';
import {mapGetters} from 'vuex';
import FormInput from '@/components/Verification/FormInput.vue';
import Spinner from '@/components/Spinner.vue';
import ApiService from '@/common/api.service';
import saveAs from 'file-saver';
import { convertDateToBackend } from '@/common/helpers';

@Component({
components: { FormInput, Spinner },
computed: mapGetters(['verificationStatistics', 'atiVerificationStatistics']),
})
export default class ReportDashboard extends Vue {
    private isLoading: boolean = false;
    private formData = {
        dateFrom: '',
        dateTo: '',
    };

    private async onSubmit() {
        if (this.isLoading || (!this.isLoading && !this.formData.dateFrom && !this.formData.dateTo)) {
            return;
        }
        this.isLoading = true;
        const dateFrom = convertDateToBackend(this.formData.dateFrom);
        const dateTo = convertDateToBackend(this.formData.dateTo);
        const resp: any = await ApiService.getFile(`/impact/user/spak_report?date_from=${dateFrom}&date_to=${dateTo}`);
        const contentDisposition = resp.headers['content-disposition'];
        let fileName = 'report.pdf';
        if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) {
            fileName = fileNameMatch[1];
        }
        }
        saveAs(resp.data, fileName);
        this.isLoading = false;

    }

}
