
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {FETCH_OFFICE_FUNCTION, FETCH_VERIFICATION_STATISTICS} from '@/store/actions.type';
import {mapGetters} from 'vuex';
import FormInput from '@/components/Verification/FormInput.vue';
import Spinner from '@/components/Spinner.vue';
import ApiService from '@/common/api.service';
import saveAs from 'file-saver';
import { convertDate, convertDateToBackend } from '@/common/helpers';
import PopUp from '@/components/PopUp.vue';
import Dropdown from '@/components/Dropdown.vue';
  
@Component({
components: { FormInput, Spinner, PopUp, Dropdown },
computed: mapGetters(['verificationStatistics', 'atiVerificationStatistics']),
})
export default class CommercialPrudenceDashboard extends Vue {
    private isLoading: boolean = false;
    private showUpload: boolean = false;
    private uploadErrorMsg = '';
    private uploadSuccessMsg = '';
    private emailSuccessMsg = '';
    private newInn: any = [];
    private oldInn: any = [];

    private clients: any = [];

    private subscriptions: any = [];
    private formData: any = {
      client_profile_id: 0,
      data: null,
    };

    private searchString: string = '';

    private async mounted() {
      const resp: any = await ApiService.query('/impact/profile');
      this.clients = resp.data;
      await this.fetchList();
    }

    private async fetchList() {
      const resp: any = await ApiService.query('/impact/commercial_prudence_subscription');
      this.subscriptions = resp.data;
    }

    private closeUpload() {
      this.showUpload = false;
      this.formData = {
        client_profile_id: 0,
        data: null,
      };
      this.searchString = '';
      this.emailSuccessMsg = '';
      this.uploadErrorMsg = '';
      this.uploadSuccessMsg = '';
    }

    get subscritionsFormatted() {
      return this.subscriptions.map((item: any) => {
        const tmp = {
          name: '',
          innCounts: item.inns.length,
          fio: '',
          clientId: item.client,
          date: convertDate(item.last_date),
          cases_checked: item.cases_checked,
          new_cases_checked: item.new_cases_checked,
        };
        const company = this.clients.filter((client: any) => client.id === item.client);
        if (company) {
          tmp.name = company[0].company;
          tmp.fio = `${company[0].last_name} ${company[0].first_name}`;
        }
        return tmp;
      });
    }

    get clientOptions() {
      let tmp = [...this.clients];
      if (this.searchString) {
        tmp = tmp.filter((item: any) =>  {
          if (!item.last_name) {
            item.last_name = '';
          }
          return item.last_name.toLowerCase().includes(this.searchString.toLowerCase()) ||
              item.first_name.toLowerCase().includes(this.searchString.toLowerCase()) ||
              item.email.includes(this.searchString.toLowerCase()) ||
              item.phone.includes(this.searchString.toLowerCase()) ||
              item.company.toLowerCase().includes(this.searchString.toLowerCase());
        })
      }
      tmp.sort((a: any, b: any) => {
        if (a.company.toLowerCase() > b.company.toLowerCase()) {
          return 1;
        } else if (a.company.toLowerCase() < b.company.toLowerCase()) {
          return -1;
        }
        const nameA = `${a.last_name} ${a.first_name}`;
        const nameB = `${b.last_name} ${b.first_name}`;
        return nameA > nameB ? 1 : -1;
      });
      return tmp.map((client: any) => ({ value: client.id, text: `${client.company} (${client.last_name} ${client.first_name})`}));
    }

    @Watch('formData.client_profile_id')
    private onClientChange() {
      this.uploadErrorMsg = '';
      this.uploadSuccessMsg = '';
      this.emailSuccessMsg = '';
      this.newInn = [];
      this.oldInn = [];
    }

    private onSearch(searchVal: string) {
      this.searchString = searchVal;
    }

    private async onSubmit() {
        if (this.isLoading || (!this.isLoading && !(this.formData.data && this.formData.client_profile_id))) {
            return;
        }
        this.isLoading = true;
        this.uploadErrorMsg = '';
        this.uploadSuccessMsg = '';
        this.emailSuccessMsg = '';
        this.newInn = [];
        this.oldInn = [];
        const data = new FormData();
        data.append('data', this.formData.data, this.formData.data.name);
        data.append('client_profile_id', this.formData.client_profile_id);
        try {
            const resp: any = await ApiService.postFile(`/impact/commercial_prudence_subscription/do_update`, data);
            this.uploadSuccessMsg = resp.data.message;
            if (resp.data.email_sent) {
              this.emailSuccessMsg = `${this.$t('strings.notificationSentTo')} <a href="mailto:${resp.data.email_sent}">${resp.data.email_sent}</a>`;
            }
            this.newInn = resp.data.new_matches;
            this.oldInn = resp.data.old_matches;
            await this.fetchList();
        } catch (e) {
          if (e.response.message) {
            this.uploadErrorMsg = e.response.message;
          }
        }
        this.isLoading = false;
    }

    private viewResults() {
      window.localStorage.setItem('innNewMatches', this.newInn.map((item: any) => item.inn).join(','));
      window.localStorage.setItem('innOldMatches', this.oldInn.map((item: any) => item.inn).join(','));
      window.localStorage.setItem('prudenceAdmin', '1');
      window.open('/prudence', '_blank');
    }

    private viewOldResults(clientId: number) {
      const subcription = this.subscriptions.filter((client: any) => client.client === clientId)[0];
      window.localStorage.setItem('prudenceAdmin', '1');
      window.localStorage.setItem('innNewMatches', subcription.new_cases_checked.map((item: any) => item.inn).join(','));
      window.localStorage.setItem('innOldMatches',
        subcription.cases_checked
          .filter((item: any) => !subcription.new_cases_checked.filter((nItem: any) => item.inn === nItem.inn && item.commercial_prudence === nItem.commercial_prudence).length).map((item: any) => item.inn).join(','));
      window.open('/prudence', '_blank');
    }

    private async onDelete(clientId: number) {
      const company = this.clients.filter((client: any) => client.id === clientId);
      if (confirm(`Вы уверены что хотите удалить записи ${company[0].company} (${company[0].last_name} ${company[0].first_name})`)) {
        await ApiService.delete(`/impact/commercial_prudence_subscription/${clientId}`);
        await this.fetchList();
      }
    }
}
