
import {Vue, Component, Watch} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {
  FETCH_USERS, CREATE_USER, FETCH_OFFICE_FUNCTION, DELETE_USER, FETCH_SEARCH_USER,
} from '@/store/actions.type';
import {convertDate} from '@/common/helpers';
import Dropdown from '@/components/Dropdown.vue';
import ApiService from '@/common/api.service';

@Component({
  components: {Dropdown},
})


export default class APIUsersDashboard extends Vue {

  private allAPIUsers: any = [];
  private allAPIClients: any = [];

  private async mounted() {
    await this.getAllAPIUsers();
  }

  get formattedUsers() {
    const apiUsers = this.allAPIUsers.map((item: any, index: number) => {
      return {
        index: index + 1,
        id: item.id,
        name: item.name,
        verificationsCount: item.verifications_count,
        type: 1,
      };
    });
    return apiUsers.concat(this.allAPIClients.map((item: any, index: number) => {
      return {
        index: index + 1 + apiUsers.length,
        id: item.id,
        name: item.company,
        verificationsCount: item.verification_count,
        type: 2,
      };
    }));
  }

  private async getAllAPIUsers() {
    let resp: any = await ApiService.query('/impact/api_key_client');
    this.allAPIUsers = resp.data;
    resp = await ApiService.query('/impact/api_client');
    this.allAPIClients = resp.data;
  }

  private viewUser(userEditId: number) {
    window.open(`/api_user/${userEditId}`, '_blank');
  }

  private viewClient(userEditId: number) {
    window.open(`/user/${userEditId}`, '_blank');
  }
}
